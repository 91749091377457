<section class="pre_authPage">
  <div class="select-login-create-account">
    <div nz-row>
      <div nz-col class="auth-card m-t-2">
        <div class="w-100 d-flex between m-b-1">
          <img
            class="login-logo pointer-cursor"
            src="/assets/images/icons/gen-black-logo.svg"
            alt="Logo Gen Business"
          />

          <img
            class="close-icon pointer-cursor"
            src="/assets/images/icons/xmark.svg"
            alt="Ícone para voltar ao login"
            (click)="goToExternal()"
          />
        </div>

        <h2>Bem-vindo, escolha a loja de sua preferência.</h2>

        <nz-row>
          <nz-col nzSpan="24">
            <form [formGroup]="formGroup">
              <nz-radio-group formControlName="choice">
                <label *ngFor="let seller of sellers" nz-radio [nzValue]="seller">
                  {{
                    seller?.stores?.displayName ||
                      seller?.company?.businessName ||
                      seller?.occupation ||
                      seller?.person?.name
                  }}
                </label>
              </nz-radio-group>
            </form>
          </nz-col>
        </nz-row>

        <div class="m-t-2 center">
          <app-primary-button
            [block]="true"
            [disabled]="!this.formGroup.get('choice').value"
            type="BLACK"
            class="center w-100"
            (click)="refreshTokenNewSeller()"
            >Entrar</app-primary-button
          >
        </div>
      </div>
    </div>
  </div>
</section>
