import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MiddlewareAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const accessToken = sessionStorage.getItem('accessToken');

    if (accessToken) {
      return true;
    } else {
      this.router.navigate(['/external']);
      return false;
    }
  }
}
