import { Component, OnInit } from '@angular/core';

import { AlertService } from 'libs/shared/src/lib/services/alert/alert.service';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { AlertDuration, AlertType, StatusAlertModel } from 'libs/shared/src/lib/models/status/status-alert.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public alertInfo: StatusAlertModel = new StatusAlertModel();
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();

  constructor(private $notification: StateManagementService, private $alert: AlertService) {}

  public ngOnInit(): void {
    this.updateAlertStatus();
  }

  public updateAlertStatus(): void {
    this.$notification.sucessAlerts.subscribe((sucessAlerts) => {
      if (sucessAlerts) {
        this.alertInfo = sucessAlerts;
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      this.alertInfo = errorAlerts;
    });
  }
}
