import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService, AuthService, ErrorService, UsersService } from '@gen/shared';
import { SellerModel } from 'libs/shared/src/lib/models/sellers/sellers.model';
import { AlertDuration, AlertType, StatusAlertModel } from 'libs/shared/src/lib/models/status/status-alert.model';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';
import { Message } from 'libs/shared/src/lib/utils/message';

@Component({
  selector: 'app-middleware',
  templateUrl: './middleware.component.html',
  styleUrl: './middleware.component.scss',
})
export class MiddlewareComponent implements OnInit {
  public alertInfo: StatusAlertModel = new StatusAlertModel();
  public alertType: AlertType = new AlertType();
  public alertDuration: AlertDuration = new AlertDuration();
  public sellers: SellerModel[];

  public formGroup!: FormGroup;

  constructor(
    private $notification: StateManagementService,
    private $auth: AuthService,
    private $user: UsersService,
    private $error: ErrorService,
    private router: Router,
    private readonly fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.createForm();
    this.getCurrentUser();
    this.getNotifications();
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      choice: new FormControl(null, [Validators.required]),
    });
  }

  public getNotifications(): void {
    this.$notification.sucessAlerts.subscribe((sucessAlerts) => {
      if (sucessAlerts) {
        this.alertInfo = sucessAlerts;
      }
    });

    this.$notification.errorAlerts.subscribe((errorAlerts) => {
      this.alertInfo = errorAlerts;
    });
  }

  public getCurrentUser(): void {
    this.$user.getCurrentUser('B2B').subscribe({
      next: (res) => {
        if (res?.data?.currentUser?.sellers.length > 0) {
          this.sellers = res.data.currentUser.sellers;
        }
      },
    });
  }

  public refreshTokenNewSeller(): void {
    const sellerId: string = this.formGroup.get('choice').value.id;
    sessionStorage.setItem('activeSellerId', sellerId);

    this.$auth.refreshTokenNewSeller(sellerId).subscribe({
      next: (res) => {
        if (res?.data?.refreshTokenNewSeller) {
          this.$auth.customLoginMultiSeller(
            res.data.refreshTokenNewSeller.authToken,
            this.formGroup.get('choice').value
          );
        }
      },

      error: (error) => {
        this.$error.errorHandling(error, error);
      },
    });
  }

  public goToExternal(): void {
    this.router.navigate(['/external']);
  }
}
